import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  finalize,
  tap,
  takeUntil,
  Subject,
  Observable,
  catchError,
  EMPTY,
} from 'rxjs';

import { ICreditCard } from '@app/shared/models/credit-card.model';
import { FlowsType } from '@app/core/enum/flows';
import { LoginFlow } from '@app/core/abstract-classes/login-flow';
import { ContextIndex } from '@app/core/models/context-index.interface';

import { StorageService } from '@app/services/storage.service';
import { RequestService } from '@app/core/services/request.service';
import { AlterModalService } from '@app/core/services/alter-modal.service';
import { RouterControlService } from '@app/services/router-control.service';
import { LoaderService } from '@app/services/loader.service';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-modal-finaliza-adesao',
  templateUrl: './modal-finaliza-adesao.component.html',
  styleUrls: ['./modal-finaliza-adesao.component.scss'],
})
export class ModalFinalizaAdesaoComponent
  extends LoginFlow
  implements OnInit, OnDestroy
{
  public listCards$: Observable<ICreditCard[]>;
  public hasCard: boolean;
  private readonly _unsubscribeAll$: Subject<void> = new Subject<void>();

  constructor(
    private _routerControl: RouterControlService,
    private _loader: LoaderService,
    private _auth: AuthService,
    protected _router: Router,
    protected _alterModal: AlterModalService,
    protected _storage: StorageService,
    protected _request: RequestService
  ) {
    super(_storage, _request, _alterModal, _router);
  }

  ngOnInit() {
    this._dispatchFlows();
  }

  ngOnDestroy() {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.unsubscribe();
  }

  public setCard = (card: ICreditCard): void => {
    this._alterModal.modalHide();

    this._storage.bin = card.bin;
    this._storage.nomeCartao = card.nome;
    this._storage.numeroCartao = card.numeroCartao;
    this._storage.tipoCartao = card.tipo;

    this._handleIndexFlow().subscribe();
  };

  public hideModal = () => {
    this._alterModal.modalHide();

    if (this._storage.flow === 'NAO_POSSUI_CARTAO') {
      this._storage.resetAllStorages();
      
      this._auth.stopTokenRefresh();

      this._router.navigate(['/']);
      
      return;
    }

    this._setRouter();
  };

  private _getCards = (): void => {
    this._setHasCard(true);
    
    this.listCards$ = this._request
      .getRequest<ICreditCard[]>(`/fatura/v1/cliente/cartao2`)
      .pipe(
        catchError(() => {
          this._alterModal.modalHide();

          this._auth.stopTokenRefresh();

          this._storage.resetAllStorages();
          
          this._handleError(1000);
          
          return EMPTY;
        })
      );
  };

  private _setRouter = (): void => {
    this._router.navigateByUrl(this._routerControl.getRouteContext());
  };

  private _dispatchFlows = (): void => {
    const dispatchFlows: Pick<
      Record<FlowsType, () => void>,
      | 'POSSUI_CARTAO'
      | 'POSSUI_DIVIDA'
      | 'POSSUI_ACORDO_ANDAMENTO'
      | 'POSSUI_DECLARACAO_DEBITOS'
      | 'NAO_POSSUI_CARTAO'
    > = {
      POSSUI_CARTAO: () => {
        this._getCards();
      },
      POSSUI_DIVIDA: () => {
        this._handleIndexFlow().subscribe();
      },
      POSSUI_ACORDO_ANDAMENTO: () => {
        this._handleIndexFlow().subscribe();
      },
      POSSUI_DECLARACAO_DEBITOS: () => {
        this._handleIndexFlow().subscribe();
      },
      NAO_POSSUI_CARTAO: () => {
        this._setHasCard(false);
      },
    };

    dispatchFlows[this._storage.flow]();
  };

  private _setHasCard = (value: boolean): void => {
    this.hasCard = value;
  };

  private _handleIndexFlow = (): Observable<ContextIndex> => {
    this._loader.show();

    return this._getIndex().pipe(
      tap((flow) => {
        this._standardFlow(flow);
        this._setRouter();
      }),
      catchError(() => {
        this._storage.resetAllStorages();

        this._auth.stopTokenRefresh();

        return EMPTY;
      }),
      finalize(() => this._loader.hide())
    );
  };
}
