<footer>
  <section
    id="protected-environment"
    class="d-flex flex-row align-items-center justify-content-center"
  >
    <img
      [src]="'./static/assets/icon/titulos/ambiente_protegido1x.png'"
      alt="Ambiente protegido"
    />
    <h1>Ambiente Protegido</h1>
  </section>

  <section id="footer" class="pt-6 pb-6">
    <div class="container-fluid">
      <div class="row no-gutters justify-content-around">
        <div class="col-12 col-lg-6 col-xl-3">
          <ul>
            <li class="title">Central de Relacionamento</li>
            <li class="subtitle">Grande SP</li>
            <li class="d-block d-md-none description">
              <a href="tel:40033900">4003 3900</a>
            </li>
            <li class="d-none d-md-block description">4003 3900</li>
            <li class="subtitle">Demais Localidades</li>
            <li class="d-block d-md-none description">
              <a href="tel:08007293900">0800 729 3900</a>
            </li>
            <li class="d-none d-md-block description">0800 729 3900</li>
            <li>
              <p>
                De segunda a sábado, das 8h às 22h. <br />
                Domingos e feriados, das 9h às 20h.
              </p>
            </li>
          </ul>
        </div>

        <div class="col-12 col-lg-6 col-xl-3">
          <ul>
            <li class="title">Central de Cobrança</li>
            <li class="subtitle">Qualquer região do Brasil</li>
            <li class="d-block d-md-none description">
              <a href="tel:(11)54690100">(11) 5469 0100</a>
            </li>
            <li class="d-none d-md-block description">(11) 5469 0100</li>
            <li class="subtitle">Telefones fora de São Paulo</li>
            <li class="d-block d-md-none description">
              <a href="tel:08008840101" class="">0800 884 0101</a>
            </li>
            <li class="d-none d-md-block description">0800 884 0101</li>
            <li>
              <p>
                Segunda a sexta, das 8h às 21h <br />e aos sábados das 8h às
                18h.
              </p>
            </li>
          </ul>
        </div>

        <div class="col-12 col-lg-6 col-xl-3">
          <ul>
            <li class="title">Ouvidoria</li>
            <li class="subtitle">Atendimento somente por telefones fixos</li>
            <li class="d-block d-md-none description">
              <a href="tel:08007775297">0800 777 5297</a>
            </li>
            <li class="d-none d-md-block description">0800 777 5297</li>
            <li>
              <p class="mb-0">
                De segunda a sexta das 9h às 17h, exceto feriados
              </p>
            </li>
          </ul>

          <ul class="link-list">
            <li class="title">Transparência</li>

            <li
              data-test="event.information-security-policy"
              (click)="modalInformationSecurityPolicy()"
            >
              Política de Segurança da Informação
            </li>

            <li>
              <a
                href="https://www.credsystem.com.br/politica-de-privacidade/ "
                target="_blank"
                data-test="event.privacy-policy"
                (click)="dispatchEventsAnalitycs('PRIVACY_POLICY')"
              >
                Política de Privacidade
              </a>
            </li>

            <li>
              <a
                href="https://www.credsystem.com.br/politica-de-cookies/"
                target="_blank"
                (click)="dispatchEventsAnalitycs('COOKIE_POLICY')"
              >
                Política de Cookies
              </a>
            </li>

            <li>
              <a
                href="https://privacidade.credsystem.com.br"
                target="_blank"
                data-test="event.privacy-portal"
                (click)="dispatchEventsAnalitycs('PRIVACY_PORTAL')"
              >
                Portal da Privacidade
              </a>
            </li>

            <li>
              <a
                href="https://www.portaldocartao.com.br/static/assets/arquivos_pdf_portal/CTCANAISDIGITAIS_TERMO.pdf"
                target="_blank"
                data-test="event.digital-channels"
                (click)="dispatchEventsAnalitycs('DIGITAL_CHANNELS')"
              >
                Termo de uso para canais digitais
              </a>
            </li>
          </ul>
        </div>

        <div id="stores" class="col-12 col-lg-6 col-xl-2">
          <ul>
            <li class="title">Baixe nosso app</li>
          </ul>

          <ul id="apps" class="d-none d-sm-block">
            <li class="mb-4">
              <a
                href="https://play.google.com/store/apps/details?id=br.com.portaldocartao&hl=pt_BR"
                target="_blank"
                data-test="event.google-play"
                (click)="dispatchEventsAnalitycs('GOOGLE_PLAY')"
              >
                <img
                  [src]="
                    './static/assets/icon/redes-sociais/get_it_on_google_play.svg'
                  "
                  alt="Acessando Google Play Portal do Cartão"
                />
              </a>
            </li>

            <li class="mb-5">
              <a
                href="https://apps.apple.com/br/app/portal-do-cart%C3%A3o/id1481888780"
                target="_blank"
                data-test="event.app-store"
                (click)="dispatchEventsAnalitycs('APP_STORE')"
              >
                <img
                  [src]="
                    './static/assets/icon/redes-sociais/available_on_the_app_store_black.svg'
                  "
                  alt="Acessando App Store Portal do Cartão"
                />
              </a>
            </li>
          </ul>

          <ul class="d-block d-sm-none">
            <li>
              <a
                class="mt-4 mb-4"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=br.com.portaldocartao&hl=pt_BR"
                (click)="dispatchEventsAnalitycs('GOOGLE_PLAY')"
              >
                <button class="btn btn-block btn-primary">Baixar agora</button>
              </a>
            </li>
          </ul>

          <ul class="link-list">
            <li class="title">Conheça a credsystem:</li>

            <li>
              <a
                href="https://www.credsystem.com.br/"
                target="_blank"
                data-test="event.acces-our-site"
                (click)="dispatchEventsAnalitycs('ACCESS_OUR_SITE')"
              >
                Acesse nosso site
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section id="legal-section" class="d-flex justify-content-center">
    <p>
      © {{ dateNow | date : 'yyyy' }} –
      <strong>credsystem</strong> CNPJ:04.670.195/0001-38 / Todos os direitos
      reservados. Proibida cópia total ou parcial deste conteúdo sem
      autorização. | Version: {{ version }}
    </p>
  </section>
</footer>
