import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  interval,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { IAuthToken } from '@app/shared/models/IAuthToken';

import { StorageService } from '@app/services/storage.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly EXPIRATION_TIME = 250 * 1000;
  private loginTimestamp$ = new BehaviorSubject<number>(
    this._getStoredTimestamp()
  );
  private readonly _unsubscribeAll$ = new Subject<void>();
  private isRefreshing: boolean = false;

  constructor(
    private _request: RequestService,
    private _storage: StorageService,
    private _router: Router
  ) {}

  public setLoginTimestamp(): void {
    const timestamp = Date.now();

    this.loginTimestamp$.next(timestamp);

    this._storage.loginTimestamp = timestamp.toString();
  }

  public startTokenRefresh(): void {  
    interval(30000)
      .pipe(
        switchMap(() => this.loginTimestamp$),
        filter((loginTimestamp) => {
          const elapsedTime = Date.now() - loginTimestamp;
          return elapsedTime >= this.EXPIRATION_TIME;
        }),
        filter(() => !this.isRefreshing),
        switchMap(() => this._refreshAccessToken()),
        takeUntil(this._unsubscribeAll$)
      )
      .subscribe();
  }

  public stopTokenRefresh = (): void => {
    this._unsubscribeAll$.next(); 
  };

  private _refreshAccessToken = (): Observable<IAuthToken> => {
    this.isRefreshing = true;

    return this._request
      .postRequest<IAuthToken>('/sso-clientes/v4/portal/refresh-token', {
        token: this._storage.refreshToken,
      })
      .pipe(
        tap((response) => {
          const newTimestamp = Date.now();

          this.loginTimestamp$.next(newTimestamp);

          this._storage.accessToken = response.accessToken;

          this._storage.refreshToken = response.refreshToken;
        }),
        catchError(() => {
          this._logout();

          this.stopTokenRefresh();

          return of(null);
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
  };

  private _getStoredTimestamp(): number {
    const storedTimestamp = this._storage.loginTimestamp;

    return storedTimestamp ? parseInt(storedTimestamp, 10) : Date.now();
  }

  private _logout = (): void => {
    this._storage.resetAllStorages();

    this._router.navigate(['sso/login']);
  };
}
