<div class="app-layout">
  <app-loader-app></app-loader-app>

  <app-nav></app-nav>

  <div class="content background-default">
    <router-outlet></router-outlet>
  </div>

  <app-footer *ngIf="isFooterVisible"></app-footer>

  <app-bottom-sheet-wrapper></app-bottom-sheet-wrapper>
</div>
