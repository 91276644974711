import { Router } from '@angular/router';

import { EMPTY, catchError, finalize, switchMap, tap } from 'rxjs';

import { ICreditCard } from '@app/shared/models/credit-card.model';
import { ErrorRequest } from '@app/shared/models/error';
import { ErrorHandler } from '../utils/error-handler';
import { LoginFlow } from './login-flow';
import { Utils } from '../utils/utils';

import { StorageService } from '@app/services/storage.service';
import { CartaoService } from '@app/services/cartao-service.service';
import { AlterModalService } from '@app/core/services/alter-modal.service';
import { LoaderService } from '@app/services/loader.service';
import { RouterControlService } from '@app/services/router-control.service';
import { RequestService } from '../services/request.service';
import { AuthService } from '../services/auth.service';

import { ModalListEmptyCardsComponent } from '@app/shared/components/modal-list-empty-cards/modal-list-empty-cards.component';
import { ModalTrocaCartaoComponent } from '@app/shared/components/modal-trocacartao/modal-trocacartao.component';

export abstract class BaseModalCardList extends LoginFlow {
  constructor(
    protected _loader: LoaderService,
    protected _request: RequestService,
    protected _router: Router,
    protected _routerControl: RouterControlService,
    protected _alterModalService: AlterModalService,
    protected _storage: StorageService,
    protected _cataoSerivice: CartaoService,
    protected _auth: AuthService,
  ) {
    super(_storage, _request, _alterModalService, _router);
  }

  protected _getListCards = (
    redirectLogin: boolean = false
  ): void => {
    this._loader.show();

    this._request
      .getRequest<ICreditCard[]>(`/fatura/v1/cliente/cartao2`)
      .pipe(
        switchMap((cards) => {
          if (cards.length === 1) {
            this._loader.show();
            
            this._singleCard(cards[0]);

            return this._getIndex(redirectLogin)
              .pipe(
                tap((value) => {
                  this._loader.hide();
                  
                  this._standardFlow(value);
                  
                  this._router.navigateByUrl(
                    this._routerControl.getRouteContext()
                  );
                })
              )
              .pipe(
                catchError(() => {
                  this._loader.hide();

                  this._storage.resetAllStorages();

                  this._auth.stopTokenRefresh();

                  return EMPTY;
                })
              );
          }
          this._loader.hide();
          
          this.showModalCardList(cards, 1000, true);
          
          return EMPTY;
        }),
        finalize(() => this._loader.hide())
      )
      .subscribe({
        error: (error: ErrorRequest) => {
          this._loader.hide();

          this._storage.resetAllStorages();

          this._auth.stopTokenRefresh();
          
          this._handleError(1000);

          if (redirectLogin) {
            this._router.navigate(['sso', 'login']);
          }
          
          ErrorHandler.handleError(error);
          
          this._setErrorEvents('Erro ao obter lista de cartões', error);
        },
      });
  };

  protected showModalCardList = (
    cardList: ICreditCard[],
    timeOut,
    login: boolean
  ): void => {
    this._alterModalService.showModal(
      ModalTrocaCartaoComponent,
      timeOut,
      {
        listCards: cardList,
        login: login,
      },
      'modal-sm',
      true
    );
  };

  protected _showModalListOfEmptyCards = (): void => {
    this._auth.stopTokenRefresh();

    this._storage.resetAllStorages();

    this._alterModalService.showModal(
      ModalListEmptyCardsComponent,
      1000,
      null,
      'modal-md',
      true,
      false
    );
  };

  protected _singleCard = (card: ICreditCard): void => {
    this._cataoSerivice.saveDataCardStorage(Utils.setSingleCard(card));
  };
}
