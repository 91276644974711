import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { BaseGuard } from '../abstract-classes/base-guard';

import { StorageService } from '@app/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends BaseGuard {
  private _isAllowedUpdatingData$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedAgreementSummary$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToConfirmData$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToValidateQuestions$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToConclusionAgreement$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToPaymentBooklet$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToPaymentAgreement$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAgreementConfirmed$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToFirstAccess$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToSendToken$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToInsertToken$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);
  private _isAllowedToCreatePassword$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(false);

  constructor(protected _storage: StorageService) {
    super(_storage);
  }

  public checkToken$ = (): Observable<boolean> => of(this._token());

  public checkUserLogged$ = (): Observable<boolean> => of(this._isUserLogged());

  public ckeckIsLoggedInFastTicket$ = (): Observable<boolean> =>
    of(this._isLoggedInFastTicket());

  public isAllowedToAccessModule$ = (route: string): Observable<boolean> =>
    of(this._isAllowedToAccessModule(route));

  public isAllowedAccessToThePaymentFlow$ = (
    route: string
  ): Observable<boolean> => of(this._storage.invoicePaymentFlow[route]);

  public setIsAllowedUpdatingData = (value: boolean): void => {
    this._isAllowedUpdatingData$.next(value);
  };

  public get isAllowedUpdatingData$(): Observable<boolean | null> {
    return this._isAllowedUpdatingData$.asObservable();
  }

  public get isAllowedAgreementSummary$(): Observable<boolean | null> {
    return this._isAllowedAgreementSummary$.asObservable();
  }

  public set isAllowedAgreementSummary(value: boolean | null) {
    this._isAllowedAgreementSummary$.next(value);
  }

  public get isAllowedToConfirmData$(): Observable<boolean | null> {
    return this._isAllowedToConfirmData$.asObservable();
  }

  public set isAllowedToConfirmData(value: boolean | null) {
    this._isAllowedToConfirmData$.next(value);
  }

  public get isAllowedToValidateQuestions$(): Observable<boolean | null> {
    return this._isAllowedToValidateQuestions$.asObservable();
  }

  public set isAllowedToValidateQuestions(value: boolean | null) {
    this._isAllowedToValidateQuestions$.next(value);
  }

  public get isAllowedToConclusionAgreement$(): Observable<boolean | null> {
    return this._isAllowedToConclusionAgreement$.asObservable();
  }

  public set isAllowedToConclusionAgreement(value: boolean | null) {
    this._isAllowedToConclusionAgreement$.next(value);
  }

  public get isAllowedToPaymentBooklet$(): Observable<boolean | null> {
    return this._isAllowedToPaymentBooklet$.asObservable();
  }

  public set isAllowedToPaymentBooklet(value: boolean | null) {
    this._isAllowedToPaymentBooklet$.next(value);
  }

  public get isAllowedToPaymentAgreement$(): Observable<boolean | null> {
    return this._isAllowedToPaymentAgreement$.asObservable();
  }

  public set isAllowedToPaymentAgreement(value: boolean | null) {
    this._isAllowedToPaymentAgreement$.next(value);
  }

  public get isAgreementConfirmed$(): Observable<boolean | null> {
    return this._isAgreementConfirmed$.asObservable();
  }

  public set isAgreementConfirmed(value: boolean | null) {
    this._isAgreementConfirmed$.next(value);
  }

  public get isAllowedToFirstAccess$(): Observable<boolean | null> {
    return this._isAllowedToFirstAccess$.asObservable();
  }

  public set isAllowedToFirstAccess(value: boolean | null) {
    this._isAllowedToFirstAccess$.next(value);
  }

  public get isAllowedToSendToken$(): Observable<boolean | null> {
    return this._isAllowedToSendToken$.asObservable();
  }

  public set isAllowedToSendToken(value: boolean | null) {
    this._isAllowedToSendToken$.next(value);
  }

  public get isAllowedToInsertToken$(): Observable<boolean | null> {
    return this._isAllowedToInsertToken$.asObservable();
  }

  public set isAllowedToInsertToken(value: boolean | null) {
    this._isAllowedToInsertToken$.next(value);
  }

  public get isAllowedToCreatePassword$(): Observable<boolean | null> {
    return this._isAllowedToCreatePassword$.asObservable();
  }

  public set isAllowedToCreatePassword(value: boolean | null) {
    this._isAllowedToCreatePassword$.next(value);
  }
}
