<div [attr.class]="'alert' + alertType + ' d-flex' + ' align-items-center'">
  <img class="pr-2" [src]="alertPathIcon" />

  <div class="d-flex flex-column">
    <ng-content></ng-content>

    <span class="title-alert" [attr.data-test]="dataTest"
      >{{ messageAlertError }}
    </span>
  </div>
</div>
