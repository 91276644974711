import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { TypeModulesContext } from '@app/core/enum/type-modules-context.enum';
import { BasePathRouterModulesEnum } from '@app/shared/utils/base-path-init-router-modules';
import { environment } from '@env/environment';
import { IAlwm } from './core/models/IAllowme';
import { Utils } from './core/utils/utils';

import { FingerPrintService } from './services/fingerprint.service';
import { StorageService } from '@app/services/storage.service';
import { GoogleTagManager } from './core/utils/google-tag-manager';

import { SharedModule } from './shared/shared.module';
import { AuthService } from './core/services/auth.service';
import { RequestService } from './core/services/request.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SharedModule],
})
export class AppComponent implements OnInit {
  public isFooterVisible: boolean = false;
  private _isExternalNegotiation: boolean = false;
  private readonly VERSION_KEY = 'appVersion';

  constructor(
    private _fingerService: FingerPrintService,
    private _router: Router,
    private _storageService: StorageService,
    private _auth: AuthService,
    private _request: RequestService,
    @Inject('Window') private _window: Window
  ) {}

  ngOnInit() {
    this._getActivatedRouter();

    GoogleTagManager.loadScript();

    this._loadAllowmeScript();

    this._initiateTokenRefreshIfLoggedIn();

    this._checkForUpdates();

    console.log(environment.version);
  }

  private _checkForUpdates = (): void => {
    this._request
      .getRequest<{ versao: string }>('/portal-cartao/v2/usuario/versao-front')
      .pipe(
        switchMap((response) => this._checkAndSetVersion(response.versao)),
        take(1)
      )
      .subscribe();
  };

  private _checkAndSetVersion(version: string): Observable<void> {
    return this._router.events.pipe(
      take(1),
      tap(() => {
        const url = new URL(this._window.location.href);

        const currentVersion = environment.version;

        const storedVersion = localStorage.getItem(this.VERSION_KEY) || '';

        if (version !== currentVersion && version !== storedVersion) {
          localStorage.setItem(this.VERSION_KEY, version);

          url.searchParams.set('v', version);

          const newUrl = `${this._window.location.origin}${this._window.location.pathname}?v=${version}`;

          this._window.location.href = newUrl;
        }
      }),
      map(() => void 0)
    );
  }

  private _getActivatedRouter = (): void => {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap({
          next: ({ url }: NavigationEnd) => {
            this._checkFooterVisibility(url);

            this._checkActiveModule(url);

            if (url.includes('negociar-externo')) {
              this._isExternalNegotiation ||= true;
            } else {
              this._loadComplianceScripts();
            }
          },
        })
      )
      .subscribe();
  };

  private _initiateTokenRefreshIfLoggedIn = (): void => {
    if (this._storageService.loginTimestamp) {
      this._auth.startTokenRefresh();
    }
  };

  private _checkFooterVisibility = (currentUrl: string): void => {
    const hiddenRoutes = [
      '/dashboard', // Rotas que começam com '/dashboard'
      '/boleto-rapido/boletos-abertos',
      '/negociar-externo',
    ];

    const isHiddenRoute = hiddenRoutes.some(
      (route) => route === currentUrl || currentUrl.startsWith(route)
    );

    this.isFooterVisible = !isHiddenRoute;
  };

  private _checkActiveModule = (url: string): void => {
    if (url.includes(BasePathRouterModulesEnum.BOOKLET)) {
      this._setActiveModule(TypeModulesContext.TYPE_MODULES_CONTEXT_BOOKLET);
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.EXTRACT)) {
      this._setActiveModule(TypeModulesContext.TYPE_MODULES_CONTEXT_EXTRACT);
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.NEGOTIATION)) {
      this._setActiveModule(
        TypeModulesContext.TYPE_MODULES_CONTEXT_NEGOTIATION
      );
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.AGREEMENT)) {
      this._setActiveModule(TypeModulesContext.TYPE_MODULES_CONTEXT_AGREEMENT);
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.INSURANCE)) {
      this._setActiveModule(TypeModulesContext.TYPE_MODULES_CONTEXT_INSURANCE);
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.OTHER_SERVICES)) {
      this._setActiveModule(
        TypeModulesContext.TYPE_MODULES_CONTEXT_OTHER_SERVICES
      );
      return;
    }
  };

  private _setActiveModule = (value: string): void => {
    this._storageService.moduloAtivo = value;
  };

  private _loadAllowmeScript = (): void => {
    this._fingerService
      .loadScript(`${environment.allowmeUrl}`)
      .then(() => {
        const allowmw: IAlwm = this._window['Alwm'];

        allowmw
          .init({ apiKey: environment.allowmeKey })
          .then(() => {
            console.log('[AllowMe] Inicializado com sucesso!');
          })
          .catch((error) => {
            console.error('[AllowMe] Falha na inicialização:', error);
          });
      })
      .catch((error: Error) => {
        console.log(error.message);
      });
  };

  private _loadComplianceScripts = (): void => {
    const storageOrigin = !!this._storageService.origin;

    if (!storageOrigin) {
      Utils.loadScript(
        'https://cdn.cookielaw.org/consent/169ca35a-538f-447b-8684-341f09aab5bc/OtAutoBlock.js'
      );

      Utils.loadScript(
        'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        {
          type: 'text/javascript',
          charset: 'UTF-8',
          'data-domain-script': '169ca35a-538f-447b-8684-341f09aab5bc',
        }
      );

      Utils.loadScript('', {
        type: 'text/javascript',
        defer: '',
      });

      const optanonScript = document.createElement('script');

      optanonScript.defer = true;

      optanonScript.textContent = `function OptanonWrapper() { }`;

      document.body.appendChild(optanonScript);
    }
  };
}
