<main>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 d-flex align-items-center content">
        <section class="d-flex flex-column align-items-start">
          <h1 class="bold mb-1">Oops!</h1>
          <p class="semibold mb-1 d-none d-lg-block">
            Não conseguimos encontrar a página que você está procurando.
          </p>
          <span class="d-none d-lg-block">Código do erro: 404</span>
          <button
            class="btn btn-primary mt-6 d-none d-lg-block"
            [routerLink]="['/']"
          >
            Voltar para Home
          </button>
        </section>
      </div>
      <div class="col-12 col-lg-6 d-flex justify-content-center no-padding-lr">
        <img
          id="error-404-image"
          class="d-none d-lg-block"
          src="../../../../static/assets/icon/Basics/error-404.svg"
          alt="Página não encontrada"
        />
        <img
          class="d-lg-none"
          src="../../../../static/assets/icon/Basics/responsive-error-404-.svg"
          alt="Página não encontrada"
        />
      </div>
      <div class="col-sm-12 d-block d-lg-none">
        <section class="d-flex flex-column align-items-start">
          <p class="semibold mb-1">
            Não conseguimos encontrar a página que você está procurando.
          </p>
          <span>Código do erro: 404</span>
          <button class="btn btn-primary mt-6" [routerLink]="['/']">
            Voltar para Home
          </button>
        </section>
      </div>
    </div>
  </div>
  <app-footer
    *ngIf="isAccessingDashboard"
    [isAccessingDashboard]="isAccessingDashboard"
  ></app-footer>
</main>
