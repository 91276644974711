import { Component, Input } from '@angular/core';

import { Alert, AlignItemsType } from '../../models/alerts.model';
@Component({
  selector: 'app-generic-alert',
  templateUrl: './generic-alert.component.html',
  styleUrls: ['./generic-alert.component.scss'],
})
export class GenericAlertComponent {
  @Input() public configAlert: Alert;
  @Input() public alignItems: AlignItemsType = 'align-items-center';
  @Input() public dataTest?: string;
}
