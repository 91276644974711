import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { tap, finalize, catchError } from 'rxjs/operators';
import { EMPTY, Subject, of, throwError } from 'rxjs';

import { AlterModalService } from '@app/core/services/alter-modal.service';
import { StorageService } from '@app/services/storage.service';
import { RequestService } from '@app/core/services/request.service';
import { LoaderService } from '@app/services/loader.service';
import { RouterControlService } from '@app/services/router-control.service';
import { CartaoService } from '@app/services/cartao-service.service';
import { AuthService } from '@app/core/services/auth.service';

import { FlowsType } from '@app/core/enum/flows';
import { BaseModalCardList } from '@app/core/abstract-classes/base-modal-card-list';
import { ErrorRequest } from '@app/shared/models/error';
import { ErrorHandler } from '@app/core/utils/error-handler';

import { ModalFinalizaAdesaoComponent } from '../modal-finaliza-adesao/modal-finaliza-adesao.component';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-content-modal-digital-invoice',
  templateUrl: './content-modal-digital-invoice.component.html',
  styleUrls: ['./content-modal-digital-invoice.component.scss'],
})
export class ContentModalDigitalInvoiceComponent
  extends BaseModalCardList
  implements OnDestroy
{
  public hasConfirmAcepptDigitalInvoice: boolean = false;
  private readonly _unsubscribeAll$: Subject<void> = new Subject<void>();

  constructor(
    protected _alterModal: AlterModalService,
    protected _storage: StorageService,
    protected _request: RequestService,
    protected _loader: LoaderService,
    protected _router: Router,
    protected _routerControl: RouterControlService,
    protected _cataoService: CartaoService,
    protected _auth: AuthService
  ) {
    super(
      _loader,
      _request,
      _router,
      _routerControl,
      _alterModal,
      _storage,
      _cataoService,
      _auth
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.unsubscribe();
  }

  public modalHide = (): void => {
    this._hideModal();

    const dispatchFlows: Pick<
      Record<FlowsType, () => void>,
      | 'POSSUI_CARTAO'
      | 'POSSUI_DIVIDA'
      | 'POSSUI_ACORDO_ANDAMENTO'
      | 'POSSUI_DECLARACAO_DEBITOS'
      | 'NAO_POSSUI_CARTAO'
    > = {
      POSSUI_CARTAO: () => {
        this._getListCards();
      },
      POSSUI_DIVIDA: () => {
        this._handleIndexFlow();
      },
      POSSUI_ACORDO_ANDAMENTO: () => {
        this._handleIndexFlow();
      },
      POSSUI_DECLARACAO_DEBITOS: () => {
        this._handleIndexFlow();
      },
      NAO_POSSUI_CARTAO: () => {
        this._showModalListOfEmptyCards();
      },
    };

    dispatchFlows[this._storage.flow]();
  };

  public confirmAcceptDigitalInvoice = (): void => {
    const dispatchFlows: Pick<
      Record<FlowsType, () => void>,
      | 'POSSUI_CARTAO'
      | 'POSSUI_DIVIDA'
      | 'POSSUI_ACORDO_ANDAMENTO'
      | 'POSSUI_DECLARACAO_DEBITOS'
      | 'NAO_POSSUI_CARTAO'
    > = {
      POSSUI_CARTAO: () => {
        this._acceptDigitalInvoice();
      },
      POSSUI_DIVIDA: () => {
        this._hideModal();
        this._handleIndexFlow();
      },
      POSSUI_ACORDO_ANDAMENTO: () => {
        this._hideModal();
        this._handleIndexFlow();
      },
      POSSUI_DECLARACAO_DEBITOS: () => {
        this._hideModal();
        this._handleIndexFlow();
      },
      NAO_POSSUI_CARTAO: () => {
        this._acceptDigitalInvoice();
      },
    };

    dispatchFlows[this._storage.flow]();
  };

  private _acceptDigitalInvoice = (): void => {
    this._loader.show();

    this._request
      .postRequest<string>(`/fatura/v1/cartao/0/fatura/digital`, null)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.Ok) {
            return of(error);
          }

          throwError(() => error);
        }),
        finalize(() => this._loader.hide())
      )
      .subscribe({
        next: () => {
          this._alterModal.modalHide();

          this._alterModal.showModal(
            ModalFinalizaAdesaoComponent,
            1000,
            null,
            'modal-md',
            true,
            false
          );
        },
        error: (error: ErrorRequest) => {
          this._alterModal.modalHide();

          this._storage.resetAllStorages();

          this._auth.stopTokenRefresh();
          
          ErrorHandler.handleError(error);
          
          this._handleError(1000);
        },
      });
  };

  private _hideModal = (): void => {
    this._alterModal.modalHide();
  };

  private _handleIndexFlow = (): void => {
    this._loader.show();

    this._getIndex()
      .pipe(
        tap((flow) => {
          this._standardFlow(flow);
          
          this._router.navigateByUrl(this._routerControl.getRouteContext());
        }),
        catchError(() => {
          this._storage.resetAllStorages();

          this._auth.stopTokenRefresh();

          return EMPTY;
        }),
        finalize(() => this._loader.hide())
      )
      .subscribe();
  };
}
