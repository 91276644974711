import { ICreditCard } from '@app/shared/models/credit-card.model';

export class Utils {
  public static setSingleCard = (card: ICreditCard): ICreditCard => ({
    ...card,
    id: 0,
  });

  public static setCards = (cards: ICreditCard[]): ICreditCard[] =>
    cards.map((card, i) => ({ ...card, id: i }));

  public static getCookie = (name: string): string | null => {
    const cookieName = `${name}=`;
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      let c = cookie.trim();
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }

    return null;
  };

  public static setCookie = (
    name: string,
    value: string,
    days: number
  ): void => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  public static removeCookies = (cookies: string[]): void => {
    cookies.forEach(cookie => {
      if (this.getCookie(cookie)) {
        this.removeCookie(cookie);
      }
    });
  }

  public static removeCookie = (name: string): void => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  };

  public static openFileInBlack(res: string) {
    if (!res) {
      console.error('Invalid PDF data provided.');
      return;
    }

    const file = this._b64toBlob(res, 'application/pdf');
    
    const fileURL = URL.createObjectURL(file);
    
    window.open(fileURL, '_blank');
  }

  public static shouldShowComponent = (
    listRoutes: string[],
    currentRouter: string,
    _document: Document
  ): boolean => {
    const isSmallScreen = _document.defaultView.innerWidth < 768;
    return !listRoutes.includes(currentRouter) || !isSmallScreen;
  };

  private static _b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;
    const dataformatted = b64Data
      .toString()
      .replace(/^[^,]+,/, '')
      .replace(/\s/g, '');
    const byteCharacters = window.atob(dataformatted);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public static convertCurrencyStringToNumber = (value: string): number => {
    const numberString = value.replace('R$', '').trim();

    const formattedString = numberString.replace(',', '.');

    const numberValue = parseFloat(formattedString);

    return numberValue;
  };
}
