<div class="d-flex alert" [ngClass]="[configAlert.type, alignItems]">
  <img class="pr-2" [src]="configAlert.pathIcon" />

  <div class="d-flex flex-column">
    <ng-content select="[before]"></ng-content>

    <span class="title-alert" [attr.data-test]="dataTest"
      >{{ configAlert.title }}
    </span>

    <ng-content select="[after]"></ng-content>
  </div>
</div>
