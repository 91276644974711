import { Router } from '@angular/router';

import { catchError, finalize, tap } from 'rxjs/operators';
import { Subject, Observable, EMPTY } from 'rxjs';

import { ICreditCard } from '@app/shared/models/credit-card.model';
import { BaseModalCardList } from '@app/core/abstract-classes/base-modal-card-list';
import { ISoldWallet } from '../models/sold-wallet.interface';
import { IFlows } from '../models/IFlows';
import { FlowsType } from '../enum/flows';

import { LoaderService } from '@app/services/loader.service';
import { AlterModalService } from '@app/core/services/alter-modal.service';
import { StorageService } from '@app/services/storage.service';
import { CartaoService } from '@app/services/cartao-service.service';
import { RouterControlService } from '@app/services/router-control.service';
import { DigitalInvoiceService } from '@app/services/digital-invoice.service';
import { RequestService } from '../services/request.service';

import { ModalSoldWalletComponent } from '@app/modules/sso-login/screens/shared/components/modal-sold-wallet/modal-sold-wallet.component';
import { ContentModalDigitalInvoiceComponent } from '@app/shared/components/content-modal-digital-invoice/content-modal-digital-invoice.component';
import { AuthService } from '../services/auth.service';

export abstract class BaseLogin extends BaseModalCardList {
  protected _listCards: Array<ICreditCard>;
  protected _isFlowListCards: boolean;
  protected _unsubscribeAll$: Subject<void> = new Subject<void>();

  constructor(
    protected _loader: LoaderService,
    protected _storage: StorageService,
    protected _cataoService: CartaoService,
    protected _router: Router,
    protected _routerControl: RouterControlService,
    protected _alterModalService: AlterModalService,
    protected _digitalInvoiceService: DigitalInvoiceService,
    protected _request: RequestService,
    protected _auth: AuthService
  ) {
    super(
      _loader,
      _request,
      _router,
      _routerControl,
      _alterModalService,
      _storage,
      _cataoService,
      _auth
    );
  }

  protected _getFlows = (): Observable<IFlows> => {
    return this._request.getRequest<IFlows>('/portal-cartao/v2/usuario/fluxo');
  };

  protected _checkFlow = (
    flows: IFlows,
    redirectLogin: boolean = false
  ): void => {
    const dispatchFlows: Record<FlowsType, (flows?: IFlows) => void> = {
      CARTEIRA_VENDIDA: (flows) => {
        this._showModalSoldWallet(flows);
      },
      FATURA_DIGITAL: () => {
        this._showModalDigitalInvoice();
      },
      POSSUI_CARTAO: () => {
        this._getListCards(redirectLogin);
      },
      POSSUI_DIVIDA: () => {
        this._handleIndexFlow(redirectLogin);
      },
      POSSUI_ACORDO_ANDAMENTO: () => {
        this._handleIndexFlow(redirectLogin);
      },
      POSSUI_DECLARACAO_DEBITOS: () => {
        this._handleIndexFlow(redirectLogin);
      },
      NAO_POSSUI_CARTAO: () => {
        this._showModalListOfEmptyCards();
      },
    };

    if (flows.fluxo.length === 1) {
      for (const flow of flows.fluxo) {
        dispatchFlows[flow](flows);
      }
      return;
    }

    if (flows.fluxo.length > 1) {
      for (const flow of flows.fluxo) {
        if (flow === 'FATURA_DIGITAL') {
          dispatchFlows['FATURA_DIGITAL']();
        }

        if (flow !== 'FATURA_DIGITAL') {
          this._storage.flow = flow;
        }
      }
      return;
    }
  };

  private _handleIndexFlow = (redirectLogin: boolean = false): void => {
    this._loader.show();

    this._getIndex(false, false, false, redirectLogin)
      .pipe(
        tap((flow) => {
          this._standardFlow(flow);
          this._router.navigateByUrl(this._routerControl.getRouteContext());
        }),
        catchError(() => {
          this._storage.resetAllStorages();

          this._auth.stopTokenRefresh();

          return EMPTY;
        }),
        finalize(() => this._loader.hide())
      )
      .subscribe();
  };

  protected _showModal = (
    component: any,
    timeOut: number,
    initialState: any,
    classStyle: string,
    ignoreBackdropClick: boolean,
    keyboard: boolean = true
  ): void => {
    this._alterModalService.showModal(
      component,
      timeOut,
      initialState,
      classStyle,
      ignoreBackdropClick,
      keyboard
    );
  };

  protected _showModalSoldWallet = ({ carteiraCliente }: ISoldWallet): void => {
    this._storage.resetAllStorages();

    this._auth.stopTokenRefresh();

    this._showModal(
      ModalSoldWalletComponent,
      0,
      carteiraCliente,
      'modal-md',
      true,
      false
    );
  };

  protected _showModalDigitalInvoice = (): void => {
    this._showModal(
      ContentModalDigitalInvoiceComponent,
      1000,
      null,
      'modal-md',
      true,
      false
    );
  };
}
