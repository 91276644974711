import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from '@app/services/storage.service';

import { environment } from '@env/environment';
import { BYPASS_INTERCEPTOR } from '@app/shared/interceptors/http-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private _COMMON_OPTIONS_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': '1EEA5C7A2EE21F1FEF344B293286',
  };
  private listApi: { API: string; OPTION_HEADER: object }[] = [
    {
      API: '/acesso-boleto-rapido',
      OPTION_HEADER: {
        ...this._COMMON_OPTIONS_HEADERS,
      },
    },
    {
      API: '/autorizador',
      OPTION_HEADER: {
        ...this._COMMON_OPTIONS_HEADERS,
      },
    },
    {
      API: '/login-unico',
      OPTION_HEADER: {
        ...this._COMMON_OPTIONS_HEADERS,
      },
    },
  ];

  constructor(private _http: HttpClient, private _storage: StorageService) {}

  public get<T>(
    url: string,
    params: any,
    bypassInterceptor?: boolean
  ): Observable<T> {
    const options = this._setOptions(params, url);

    return this._http.get<T>(`${url}`, {
      ...options,
      context: new HttpContext().set(BYPASS_INTERCEPTOR, bypassInterceptor),
    });
  }

  public post<T>(
    url: string,
    data: any,
    params: any,
    bypassInterceptor?: boolean
  ): Observable<T> {
    const options = this._setOptions(params, url);

    const body = JSON.stringify(data);

    return this._http.post<T>(`${url}`, body, {
      ...options,
      context: new HttpContext().set(BYPASS_INTERCEPTOR, bypassInterceptor),
    });
  }

  private _setOptions = (
    params: any,
    url?: string
  ): { headers: HttpHeaders; params?: HttpParams } => {
    const headersConfig: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    
    if (this._storage.accessToken) {
      headersConfig['Authorization'] = `Bearer ${this._storage.accessToken}`;
    }
    
    const _headers: HttpHeaders = new HttpHeaders(headersConfig);
    
    const options: any = {};

    this._checkUrls(url)
      ? (options['headers'] = this._setHeaders(url))
      : (options['headers'] = _headers);

    if (params) {
      options['params'] = this._serializesParameters(params);
    }

    return options;
  };

  private _serializesParameters = (params: any): HttpParams | undefined => {
    let httpParams: HttpParams | undefined;

    if (params) {
      httpParams = new HttpParams();
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.append(key, params[key]);
      });
    }

    return httpParams;
  };

  private _setHeaders = (url: string): HttpHeaders => {
    let header: HttpHeaders;

    this.listApi.forEach(({ API, OPTION_HEADER }) => {
      if (url.includes(API)) {
        header = this._appendHeaders(OPTION_HEADER);
      }
    });

    return header;
  };

  private _appendHeaders = (params: any): HttpHeaders => {
    let httpHeaders: HttpHeaders | undefined;

    if (params) {
      httpHeaders = new HttpHeaders();
      Object.keys(params).forEach((key) => {
        httpHeaders = httpHeaders.append(key, params[key]);
      });
    }

    return httpHeaders;
  };

  private _checkUrls = (url: string): boolean =>
    [
      this._adjustPath('/sso-clientes/v4/portal/login-unico'),
      this._adjustPath('/sso-clientes/v4/portal/acesso-boleto-rapido'),
      this._adjustPath('/sso-clientes/v4/portal/autorizador'),
    ].includes(this._adjustPath(url));

  private _adjustPath(path: string): string {
    return path.startsWith('/') ? `${environment.urlBase}${path}` : path;
  }
}
