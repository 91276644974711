import { ErrorRequest } from '@app/shared/models/error';

import { environment } from '../../../environments/environment';
import { LabelsEventsGeneralEnum } from '../enum/labels-events-general.enum';
import { ErrorHandler } from './error-handler';

export class GoogleTagManager {
  private static _dataLayerName = 'dataLayer';
  private static _scriptId = 'GTMscript';
  private static _ready: boolean;
  private static _dataLayer: any[] = [];
  private static _categoriesEvents = {
    user: 'Usuário',
    payNow: 'Pagar Agora',
    negotiation: 'Negociação',
    exceptions: 'Exceptions',
    erro: 'Erro Javascript',
    doubts: 'Dúvidas',
    sso: 'SSO',
    fastTicket: 'Boleto Rápido',
    invoice: 'Faturas',
    booklet: 'Carnês',
    generalEvents: 'Eventos Gerais',
    insurance: 'Seguros e assistências',
    otherServices: 'Outros serviços',
  };

  public static loadScript = (): void => {
    const { gtm_src, gtm_container_id } = environment;

    // Data Layer
    const layerDataBase: any = {
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    };

    if (!environment.production) {
      window[GoogleTagManager._dataLayerName] =
        window[GoogleTagManager._dataLayerName] || [];
      window[GoogleTagManager._dataLayerName].push(layerDataBase);
      GoogleTagManager._dataLayer = window[GoogleTagManager._dataLayerName];

      // Script
      const head = document.getElementsByTagName('style')[0];
      const scriptEl = document.createElement('script');
      scriptEl.id = GoogleTagManager._scriptId;
      head.parentNode.insertBefore(scriptEl, head);
      scriptEl.onload = () => {
        GoogleTagManager._ready = true;
      };
      scriptEl.async = true;
      scriptEl.src = `${gtm_src}?id=${gtm_container_id}&gtm_cookies_win=x`;
    }
  };

  public static setInvoiceEvents = (action: string, label: string): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.invoice,
      action,
      label,
      ''
    );
  };

  public static setFastTicketEvents = (action: string, label: string): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.fastTicket,
      action,
      label,
      ''
    );
  };

  public static setSsoEvents = (action: string, label: string): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.sso,
      action,
      label
    );
  };

  public static setErrorEvents = (
    action: string,
    label: string,
    error: ErrorRequest
  ): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.exceptions,
      action,
      `${label} - ${ErrorHandler.errorMessage(error)}`,
      ''
    );
  };

  public static setBookletEvents = (action: string, label: string): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.booklet,
      action,
      label,
      ''
    );
  };

  public static setInsuranceEvents = (action: string, label: string): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.insurance,
      action,
      label,
      ''
    );
  };

  public static setNegotiationEvents = (
    action: string,
    label: string
  ): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.negotiation,
      action,
      label,
      ''
    );
  };

  public static setOtherServices = (action: string, label: string): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.otherServices,
      action,
      label,
      ''
    );
  };

  public static setGeneralHomeEventsAnalitycs = (action: string): void => {
    GoogleTagManager.setGeneralEvents(
      action,
      LabelsEventsGeneralEnum.LABELS_GENERAL_HOME_CONTEXT
    );
  };

  public static setGeneralFaqEventsAnalitycs = (action: string): void => {
    GoogleTagManager.setGeneralEvents(
      action,
      LabelsEventsGeneralEnum.LABELS_GENERAL_FAQ_CONTEXT
    );
  };

  public static setGeneralEvents = (action: string, label: string): void => {
    GoogleTagManager._setEventAnalytics(
      GoogleTagManager._categoriesEvents.generalEvents,
      action,
      label,
      ''
    );
  };

  private static _setEventAnalytics = (
    category: string,
    action: string,
    label: string,
    value: string = ''
  ): void => {
    GoogleTagManager._dataLayer.push({
      event: 'gaEvent',
      gaEventCategory: category,
      gaEventAction: action,
      gaEventLabel: label,
      frontendVersion: `Versão ${environment.version}`,
      gaEventValue: value,
    });
  };
}
