import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';
import { StorageService } from '../../../../services/storage.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  public envVersion: string = environment.version;
  public isButtonVisible: boolean = false;
  public isNavVisible: boolean = false;
  private readonly ROUTE_FAST_TICKET: string = '/boleto-rapido/boletos-abertos';

  constructor(private _router: Router, private _storage: StorageService) {}

  ngOnInit() {
    this._listenToRouterEvents();
  }

  private _listenToRouterEvents(): void {
    this._router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this._checkNavVisibility(event.urlAfterRedirects);
      });
  }

  private _checkNavVisibility = (currentUrl: string): void => {
    const hiddenRoutes = ['/dashboard', '/negociar-externo'];

    const isHiddenRoute = hiddenRoutes.some(
      (route) => currentUrl.startsWith(route)
    );

    this.isNavVisible = !isHiddenRoute;

    this.isButtonVisible = currentUrl === this.ROUTE_FAST_TICKET;
  };

  public logout = (): void => {
    this._storage.resetAllStorages();
    this._router.navigate(['/']);
  };
}
