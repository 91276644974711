import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpStatusCode,
  HttpErrorResponse,
  HttpContextToken,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';

import { StorageService } from '@app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/core/services/auth.service';

export const BYPASS_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private _storage: StorageService,
    private _toast: ToastrService,
    private _router: Router,
    private _auth: AuthService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const bypass = request.context.get(BYPASS_INTERCEPTOR);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const urlContext: Record<'fastTicket' | 'default', string[]> = {
          fastTicket: ['boleto-rapido'],
          default: ['sso', 'login'],
        };

        if (this._isUnauthorizedUser(error) && !bypass) {
          this._storage.resetAllStorages();

          this._auth.stopTokenRefresh();

          this._toast.error('Usuário não autorizado');

          this._checkUrlFastTicketContext(error.url)
            ? this._navigate(urlContext['fastTicket'])
            : this._navigate(urlContext['default']);
        }

        return throwError(() => error);
      })
    );
  }

  private _isUnauthorizedUser = (error: HttpErrorResponse): boolean =>
    error.status === HttpStatusCode.Unauthorized;

  private _checkUrlFastTicketContext = (url: string): boolean =>
    url.indexOf('acesso-rapido') !== -1 || url.indexOf('boleto-rapido') !== -1;

  private _navigate = (url: string[]): void => {
    this._router.navigate(url);
  };
}
